<template>
    <div class="BusinessPaymentView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name" @show_pp="show_popup = true"></nav-bar-component>
        <van-popup
            v-model="show_popup"
            position="top"
            round
            :close-on-click-overlay="false"
            closeable   
        >
            <van-form :key="form_key">
                <div class="search_title">
                    <van-tag round type="success" size="large">条件搜索区</van-tag>
                </div>
                <van-field
                    v-model="cond.name"
                    name="name"
                    label="付款单号"
                    placeholder="付款单号"
                />
                <select-component
                    name="state"
                    lable="审核状态"
                    :farr="this.$store.state.state"
                    @clk="confirm_state"
                ></select-component>
                <select-component
                    name="urgent_state"
                    lable="紧急状态"
                    :farr="[{id: 0,name: '一般'},{id: 1,name: '紧急'},{id: 2,name: '特急'}]"
                    @clk="confirm_urgent_state"
                ></select-component>
                <date-component
                    title="付款日期"
                    @clk="confirm_zhwl_pay_time"
                ></date-component>
                <select-component
                    name="enterprise_id"
                    lable="企业"
                    :farr="all_enterprise"
                    @clk="confirm_enterprise"
                ></select-component>
                <select-component
                    name="department_id"
                    lable="部门"
                    :farr="all_department"
                    @clk="confirm_department"
                ></select-component>
                <van-field
                    v-model="cond.create_staff_name"
                    name="create_staff_name"
                    label="创建人"
                    placeholder="创建人"
                />
                <van-field
                    v-model="cond.zhwl_yw_id"
                    name="zhwl_yw_id"
                    label="业务付款编号"
                    placeholder="业务付款编号"
                />
                <van-field
                    v-model="cond.zhwl_pay_entertain_name"
                    name="zhwl_pay_entertain_name"
                    label="收款单位"
                    placeholder="收款单位"
                />
                <van-field
                    v-model="cond.zhwl_operator_name"
                    name="zhwl_operator_name"
                    label="申请人"
                    placeholder="申请人"
                />
                <div class="search_button">
                    <van-button round block type="info" icon="search" @click="search">搜 索</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="warning" @click="reset">重 置</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="default" @click="show_popup = false">取 消</van-button>
                </div>
            </van-form>
        </van-popup>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.name" @click="show_details(v)">
                <van-cell>
                    <template #title>
                        <span class="title_span">{{v.name}}</span>
                    </template>
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="v.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="v.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="v.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="v.state===3">已驳回</van-tag>
                        <span>&nbsp;</span>
                        <van-tag round color="#ADADAD" v-if="v.urgent_state===0">一般</van-tag>
                        <van-tag round type="warning" v-else-if="v.urgent_state===1">紧急</van-tag>
                        <van-tag round type="danger" v-else-if="v.urgent_state===2">特急</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="付款日期" :value="v.zhwl_pay_time | div_zhwl_pay_time" :border="false" />
                <van-cell class="note_cell" title="收款单位" :value="v.zhwl_pay_entertain_name" :border="false" />
                <van-cell class="note_cell" title="申请人" :value="v.zhwl_operator_name" :border="false" />
                <van-cell class="note_cell" title="付款编号" :value="v.zhwl_yw_id" :border="false" />
                <van-cell class="note_cell" title="币种" :value="v.zhwl_currency" :border="false" />
                <van-cell class="note_cell" title="付款金额" :value="v.zhwl_money | diy_money" :border="false" />
                <van-cell class="note_cell" title="申请企业" :value="v.enterprise_name" :border="false" />
                <van-cell class="note_cell" title="创建人" :value="v.create_staff_name" :border="false" />
                <van-cell class="note_cell" title="当前审批人" :value="v.current_examine_staff_name" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import DateComponent from '@/components/common/DateComponent.vue'

import { business_payment_index_request } from '@/network/finance/BusinessPayment.js'
import { enterprise_list_request,department_list_request } from '@/network/list.js'

import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name:'BusinessPaymentView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: 'text',
                    name: '搜索'
                },
            },
            my_nav_bar_name: '业务付款单管理',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            show_popup: false,
            form_key: Date.now(),
            cond: {
                name: '',
                state: '',
                urgent_state: '',
                enterprise_id: '',
                department_id: '',
                create_staff_name: '',
                zhwl_yw_id: '',
                zhwl_pay_entertain_name: '',
                zhwl_operator_name: '',
                zhwl_pay_time: '',
                page: 1
            },
            all_enterprise: [],
            all_department: []
        }
    },
    computed:{},
    methods:{
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_business_payment() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            business_payment_index_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total =s.reuult.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    // this.$toast.fail(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_state(e) {
            this.cond.state = e.id
        },
        confirm_urgent_state(e) {
            this.cond.urgent_state = e.id
        },
        confirm_enterprise(e) {
            this.cond.enterprise_id = e.id
            this.cond.department_id = ''
            department_list_request({enterprise_id: e.id})
                .then((s) => {
                    if (s.status === 0) {
                        this.all_department = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_department(e) {
            this.cond.department_id = e.id
        },
        confirm_zhwl_pay_time(e) {
            this.cond.zhwl_pay_time = e
        },
        search() {
            this.cond.page = 1
            this.get_business_payment()
            this.show_popup = false
        },
        reset() {
            this.cond = {
                name: '',
                state: '',
                urgent_state: '',
                enterprise_id: '',
                department_id: '',
                create_staff_name: '',
                zhwl_yw_id: '',
                zhwl_pay_entertain_name: '',
                zhwl_operator_name: '',
                zhwl_pay_time: '',
                page: 1
            }
            this.form_key = Date.now()
        },
        show_details(v) {
            if(this.$_has('业务付款申请详情')) {
                this.$router.push({
                    name: 'BusinessPaymentDetails',
                    query: {
                        id: v.id
                    }
                })
            }
        },
        onLoad() {
            setTimeout(() => { 
                this.cond.page++
                business_payment_index_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
    },
    filters:{
        diy_money(v) {
            return thousandBitSeparator(fomatFloat(v))
        },
        div_zhwl_pay_time(v) {
            return v.slice(0,10)
        }
    },
    props:{},
    created(){
        this.get_business_payment()
        this.get_all_enterprise()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        DateComponent
    },
    watch:{}
}
</script>

<style>
.BusinessPaymentView .van-cell-group {
    margin-bottom: 0.2rem;
}
.BusinessPaymentView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.BusinessPaymentView .note_cell {
    padding: 0.1rem 0.42667rem
}
.BusinessPaymentView .search_title {
    text-align: center;
}
.BusinessPaymentView .search_button {
    margin: 0.5rem;
}
.BusinessPaymentView .insert {
    text-align: center;
    /* position: absolute; */
        transform: translateY(-40%);
}
</style>